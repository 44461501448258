import React, { useState, useEffect } from 'react';
import { Box, FormControl, Checkbox, TextField, Autocomplete } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from 'pages/newtable';
import { useLocation } from 'react-router-dom';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TagRenderer from 'components/TagRenderer';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 400,
    },
}));

const staticOptions = ["EKS","Non EKS","Linux","Windows"];

const Instance = () => {
    const location = useLocation();
    const { r } = location.state || {};
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const encodedData = localStorage.getItem('filtersData');
    const decodedData = atob(encodedData);
    const result = JSON.parse(decodedData);
    const email = result.email;
    const appLists = result.app_list;
    const accountLists = result.accounts;
    const serviceLists = result.ins_services;
    const tagStatusOptions = result.tag_status;
    const instanceStatusOptions = [...staticOptions, ...result.instance_status];
    const regionOptions = result.region;
    const metricsOptions = result.metrics;
    const fleetOptions = result.fleet;
    const devopsStatusOptions = result.devops_status;
    const ins_name = r?.name ? [r.name] : [];
    const instanceUrl = `${baseUrl}resource/instance/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
    const appGroupsData = result.app_group;

    const [appList, setAppList] = useState([]);
    const [appGroups, setAppGroups] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [selectedServices, setSelectedServices] = useState(r?.service ? [r.service] : []);
    const [tagStatus, setTagStatus] = useState(r?.tag ? [r.tag] : []);
    const [regions, setRegions] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [fleet, setFleet] = useState([]);
    const [instanceStatuses, setInstanceStatuses] = useState(r?.status ? [r.status] : []);
    const [devopsStatuses, setDevopsStatuses] = useState([]);
    const [data, setData] = useState([]);
    const labels = devopsStatusOptions.map(item => item.label);
    useEffect(() => {
        const fetchData = async () => {
          //const app_list = appList.length > 0 ? appList : appLists;
            const response = await fetch(`${instanceUrl}?email=${encodeURIComponent(email)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    account: selectedAccounts.includes('Select All') ? accountLists : selectedAccounts,
                    service: selectedServices.includes('Select All') ? serviceLists : selectedServices,
                    app_list: appList.includes('Select All') ? appLists : appList,
                    tag_status: tagStatus.includes('Select All') ? tagStatusOptions : tagStatus,
                    region: regions.includes('Select All') ? regionOptions : regions,
                    metrics: metrics.includes('Select All') ? metricsOptions : metrics,
                    fleet: fleet.includes('Select All') ? fleetOptions : fleet,
                    instance_status: instanceStatuses.includes('Select All') ? instanceStatusOptions : instanceStatuses,
                    devops_status: devopsStatuses.includes('Select All') ? devopsStatusOptions : devopsStatuses,
                    ins_name: ins_name,
                    app_groups: appGroups.includes('Select All') ? appGroupsData : appGroups,
                })
            });
            const data = await response.json();
            setData(data);
        };

        fetchData();
    }, [appList, selectedAccounts, selectedServices, tagStatus, regions, instanceStatuses, devopsStatuses, appGroups,metrics, fleet]); // Add devopsStatuses to the dependency array

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const handleSelectAll = (newValue, list, setList, allItems) => {
        if (Array.isArray(newValue)) {
          if (newValue.includes('Select All')) {
            if (list.length === allItems.length) {
              setList([]); 
            } else {
              setList(allItems);
            }
          } else {
            setList(newValue); 
          }
        }
      };
    
      const isAllSelected = (list, allItems) => list.length === allItems.length;

    return (
        <Box m="20px">
            <FormControl className={classes.formControl} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...appLists]}
                    disableCloseOnSelect
                    value={appList}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(appList, appLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Applications" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, appList, setAppList, appLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Autocomplete
                multiple
                id="app-groups"
                size="small"
                options={[{ value: 'Select All', label: 'Select All' }, ...appGroupsData]}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={option.value === 'Select All' ? isAllSelected(appGroups, appGroupsData) : selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="App Groups" />
                )}
                value={appGroups.includes('Select All') ? [{ value: 'Select All', label: 'Select All' }]: appGroupsData.filter(option => appGroups.includes(option.value))}
                onChange={(event, newValue) => {
                  if (newValue.some(option => option.value === 'Select All')) {
                    if (appGroups.length === appGroupsData.length) {
                      setAppGroups([]); 
                    } else {
                      setAppGroups(appGroupsData.map(group => group.value));
                    }
                  } else {
                    const selectedValues = newValue.map(option => option.value);
                    setAppGroups(selectedValues);
                  }
                }}
                renderTags={(value, getTagProps) => (
                  <TagRenderer value={value} getTagProps={getTagProps} />
                )}
                
              />
            </FormControl>
            <FormControl className={classes.formControl} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...accountLists]}
                    disableCloseOnSelect
                    value={selectedAccounts}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(selectedAccounts, accountLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Accounts" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, selectedAccounts, setSelectedAccounts, accountLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <FormControl className={classes.formControl} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...serviceLists]}
                    disableCloseOnSelect
                    value={selectedServices}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(selectedServices, serviceLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Services" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, selectedServices, setSelectedServices, serviceLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <FormControl className={classes.formControl} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...regionOptions]}
                    disableCloseOnSelect
                    value={regions}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(regions, regionOptions) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Regions" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, regions, setRegions, regionOptions);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <FormControl className={classes.formControl} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...tagStatusOptions]}
                    disableCloseOnSelect
                    value={tagStatus}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(tagStatus, tagStatusOptions) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Tag Status" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, tagStatus, setTagStatus, tagStatusOptions);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            
            <FormControl className={classes.formControl}>
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...instanceStatusOptions]}
                    disableCloseOnSelect
                    value={instanceStatuses}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(instanceStatuses, instanceStatusOptions) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Instance Status" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, instanceStatuses, setInstanceStatuses, instanceStatusOptions);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>

            <FormControl className={classes.formControl}>
                <Autocomplete
                    multiple
                    id="devops-status"
                    size="small"
                    options={[{ value: 'Select All', label: 'Select All' }, ...devopsStatusOptions]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option.value === 'Select All' ? isAllSelected(devopsStatuses, devopsStatusOptions) : selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (<TextField {...params} label="DevOps Status" />)}
                    value={devopsStatuses.includes('Select All') ? [{ value: 'Select All', label: 'Select All' }]: devopsStatusOptions.filter(option => devopsStatuses.includes(option.value))}
                    onChange={(event, newValue) => {
                    if (newValue.some(option => option.value === 'Select All')) {
                        if (devopsStatuses.length === devopsStatusOptions.length) {
                            setDevopsStatuses([]); 
                        } else {
                            setDevopsStatuses(devopsStatusOptions.map(group => group.value));
                        }
                    } else {
                        const selectedValues = newValue.map(option => option.value);
                        setDevopsStatuses(selectedValues);
                    }
                    }}
                    renderTags={(value, getTagProps) => (
                    <TagRenderer value={value} getTagProps={getTagProps} />
                    )}
                />
            </FormControl>
            <FormControl className={classes.formControl} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...metricsOptions]}
                    disableCloseOnSelect
                    value={metrics}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(metrics, metricsOptions) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Metrics" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, metrics, setMetrics, metricsOptions);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <FormControl className={classes.formControl} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...fleetOptions]}
                    disableCloseOnSelect
                    value={fleet}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(fleet, fleetOptions) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Fleet" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, fleet, setFleet, fleetOptions);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>

            <DataTable res={data} title="Instance Data" labels={labels}/>
        </Box>
    );
};

export default Instance;
