import React, { useMemo,useState, useEffect } from 'react';
import { CssBaseline, ThemeProvider,Box } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from 'pages/layout';
import Dashboard from 'pages/dashboard';
import CostExplorer from 'pages/costexplorer';
import Login from 'pages/login';
import Logout from 'pages/logout';
import { validateSession } from "api/validation";
import AuthConstants from 'common/authConstants';
import { themeSettings } from 'theme';
import CircularProgress from '@mui/material/CircularProgress';
import NewApi from 'api/newApi';
import Instance from 'pages/instance';
import NodePing from 'pages/nodeping';
import Kafka from 'pages/kafkatopic';
import Consumer from 'pages/kafkaconsumer';
import CostAnomaly from 'pages/costanomaly';
import AppInfo from 'pages/appinfo';
import CostSummary from 'pages/costsummary';
import Contacts from 'pages/contacts';
import NodepingResults from 'pages/nodeping/nodepingresults';
import Schedule from 'pages/schedule';
import Squadcast from 'pages/squadcast';
import AWSVendor from 'pages/awsvendor';
import SaasBilling from 'pages/saasbilling';
import Elastic from 'pages/elastic';
import AWSBilling from 'pages/appbilling';
import Domains from 'pages/domains';
import CFUsage from 'pages/cfusage';
import SaasBillingReport from 'pages/saasbilling/saasbillingreport';
import AWSVendorReport from 'pages/awsvendor/vendorreport';
import SaasInfo from 'pages/saasinfo';
import CostAnomalyConfig from 'pages/costanomaly/costanomalyconfig';
import MicroServicesMaster from 'pages/mis/microservicesmaster';
import ToolsMaster from 'pages/mis/toolsmaster';
import MisAppDependencies from 'pages/mis/misappdependencies';
import MisAppServices from 'pages/mis/misappservices';
import MisAppTools from 'pages/mis/misapptools';
import AppService from 'pages/appservice';
function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const [dynamicRoutes, setDynamicRoutes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const encodedData = localStorage.getItem('encodedData');

      if (encodedData) {
        const decodedData = atob(encodedData);
        const data = JSON.parse(decodedData);
        const menuList = data.menu_list || [];

        const generateRoutesRecursive = (items) => {
          return items.flatMap(({ text, menu_url, subItems }) => {
            const lcText = text.toLowerCase().replace(/\s/g, "");


            const routes = subItems
              ? [
                  ...generateRoutesRecursive(subItems),
                  (
                    <Route
                      key={lcText}
                      path={`/${lcText}`}
                      element={<NewApi endpoint={menu_url} title={text}/>}
                    />
                  ),
                ]
              : (
                <Route
                  key={lcText}
                  path={`/${lcText}`}
                  element={<NewApi endpoint={menu_url} title={text} />}
                />
              );

            return routes;
          });
        };

        const generatedRoutes = generateRoutesRecursive(menuList);

        setDynamicRoutes(generatedRoutes);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login/>} />
            <Route path="/logout" element={<Logout/>} />
            <Route path="/auth" element={<AuthConstants/>} />
            <Route element={<ProtectedRoute><Layout /></ProtectedRoute>} >

               <Route path="/dashboard" element={<Dashboard />} />
               <Route path="/costexplorer" element={<CostExplorer />} />
               <Route path="/resources" element={<Instance />} />
               <Route path="/nodeping" element={<NodePing />} />
               <Route path="/kafka" element={<Kafka />} />
               <Route path="/consumer" element={<Consumer />} />
               <Route path="/costanomaly" element={<CostAnomaly />} />
               <Route path="/appinfo" element={<AppInfo />} />
               <Route path="/costsummary" element={<CostSummary />} />
               <Route path="/contacts" element={<Contacts />} />
               <Route path="/schedule" element={<Schedule />} />
               <Route path="/nodepingresults" element={<NodepingResults />} />
               <Route path="/squadcast" element={<Squadcast />} />
               <Route path="/awsappbilling" element={<AWSBilling />} />
               <Route path="/saasbilling" element={<SaasBilling />} />
               <Route path="/saasbillingreport" element={<SaasBillingReport />} />
               <Route path="/elastic" element={<Elastic />} />
               <Route path="/awsvendorbilling" element={<AWSVendor />} />
               <Route path="/awsvendorbillingreport" element={<AWSVendorReport />} />
               <Route path="/domains" element={<Domains />} />
               <Route path="/cfusage" element={<CFUsage />} />
               <Route path="/saastoolinfo" element={<SaasInfo />} />
               <Route path="/costanomalyconfig" element={<CostAnomalyConfig />} />
               <Route path="/microservicesmaster" element={<MicroServicesMaster />} />
               <Route path="/toolsmaster" element={<ToolsMaster />} />
               <Route path="/app&dependencies" element={<MisAppDependencies />} />
               <Route path="/app&services" element={<MisAppServices />} />
               <Route path="/app&tools" element={<MisAppTools />} />
               <Route path="/appservice" element={<AppService />} />
                {dynamicRoutes}
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}
const ProtectedRoute = ({ children }) => {
  const [isSessionValid, setIsSessionValid] = useState(null);
  useEffect(() => {
    const checkSession = async () => {
      try {
        const sessionValid = await validateSession();
        setIsSessionValid(sessionValid);

      } catch (error) {
        console.error('Error checking session:', error);
        setIsSessionValid(false);
      }
    };

    checkSession();
  }, []);
  return isSessionValid !== null ? (
    isSessionValid ? (
      children
    ) : (
      <Navigate to="/logout" replace />
    )
  ) : (
    <Box sx={{ marginX: '50%' ,marginY: '25%'}}>
      <CircularProgress color="secondary" thickness={4} size={60} />
    </Box>
  );
};
export default App;
