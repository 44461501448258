import React, { useState, useEffect } from 'react';
import { Box, FormControl, Checkbox, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from 'pages/newtable';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 300,
  },
  dropdownContainer: {
    display: 'flex',
    gap: '20px',
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Domains = () => {
  const classes = useStyles();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const encodedData = localStorage.getItem('filtersData');
  const decodedData = atob(encodedData);
  const result = JSON.parse(decodedData);
  const email = result.email;
  const appLists = result.app_list;
  const domainOptions = result.domains;
  const recordTypeOptions=result.record_type;
  const dnsProviderOptions=result.dns_provider;
  const domainStatus=result.domains_status;
  const dnsManagerOptions=result.dns_manager;
  const dnsDelegationOptions=result.dns_delegation;
  const wafOptions=result.waf;
  const cdnOptions=result.cdn;
  const domainTableUrl = `${baseUrl}auditmonitoring/domains/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const [domains, setDomains] = useState([]);
  const [appList, setAppList] = useState([]);
  const [recordTypes, setRecordTypes] = useState([]);
  const [dnsProviders, setDnsProviders] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [dnsManagers, setDnsManagers] = useState([]);
  const [dnsDelegations, setDnsDelegations] = useState([]);
  const [waf, setWaf] = useState([]);
  const [cdn, setCdn] = useState([]);
  const [data, setData] = useState([]);
  const labels = domainStatus.map((item) => item.label);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${domainTableUrl}?email=${encodeURIComponent(email)}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            domains: domains,
            application_ids: appList,
            record_types: recordTypes,
            dns_providers: dnsProviders,
            statuses: statuses,
            dns_managers: dnsManagers,
            dns_delegations: dnsDelegations,
            waf: waf,
            cdn: cdn,
          })
        });

        if (response.ok) {
          const data = await response.json();
          setData(data);
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [domains, appList, recordTypes, dnsProviders, statuses, dnsManagers, dnsDelegations, waf, cdn]);

  return (
    <Box m="20px">
      <FormControl className={classes.formControl} >
        <Autocomplete
          multiple
          id="domains-autocomplete"
          size="small"
          options={domainOptions}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 300, maxWidth: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="Domains" placeholder="Select Domains" />
          )}
          value={domains}
          onChange={(event, newValue) => {
            setDomains(newValue);
          }}
        />
    </FormControl>
    <FormControl className={classes.formControl} >
        <Autocomplete
          multiple
          id="applications-autocomplete"
          size="small"
          options={appLists}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 300, maxWidth: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="Applications" placeholder="Select Applications" />
          )}
          value={appList}
          onChange={(event, newValue) => {
            setAppList(newValue);
          }}
        />
    </FormControl>
    <FormControl className={classes.formControl} >
        <Autocomplete
          multiple
          id="record-types-autocomplete"
          size="small"
          options={recordTypeOptions}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 300, maxWidth: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="Record Types" placeholder="Select Record Types" />
          )}
          value={recordTypes}
          onChange={(event, newValue) => {
            setRecordTypes(newValue);
          }}
        />
    </FormControl>
    <FormControl className={classes.formControl} >
        <Autocomplete
          multiple
          id="dns-providers-autocomplete"
          size="small"
          options={dnsProviderOptions} // Replace with DNS providers options if available
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          sx={{ width: 300, maxWidth: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="DNS Providers" placeholder="Select DNS Providers" />
          )}
          value={dnsProviderOptions.filter(option => dnsProviders.includes(option.value))}
          onChange={(event, newValue) => {
            const selectedValues = newValue.map((option) => option.value);
            setDnsProviders(selectedValues);
          }}
        />
    </FormControl>
    <FormControl className={classes.formControl} >
        <Autocomplete
          multiple
          id="statuses-autocomplete"
          size="small"
          options={domainStatus}
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          sx={{ width: 300, maxWidth: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="Statuses" placeholder="Select Statuses" />
          )}
          value={domainStatus.filter(option => statuses.includes(option.value))}
          onChange={(event, newValue) => {
            const selectedValues = newValue.map((option) => option.value);
            setStatuses(selectedValues);
          }}
        />
      </FormControl>
      <FormControl className={classes.formControl} >
        <Autocomplete
          multiple
          id="dns-managers-autocomplete"
          size="small"
          options={dnsManagerOptions} 
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          sx={{ width: 300, maxWidth: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="DNS Managers" placeholder="Select DNS Managers" />
          )}
          value={dnsManagerOptions.filter(option => dnsManagers.includes(option.value))}
          onChange={(event, newValue) => {
            const selectedValues = newValue.map((option) => option.value);
            setDnsManagers(selectedValues);
          }}
        />
      </FormControl>
      <FormControl className={classes.formControl} >
        <Autocomplete
          multiple
          id="dns-delegations-autocomplete"
          size="small"
          options={dnsDelegationOptions} 
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          sx={{ width: 300, maxWidth: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="DNS Delegations" placeholder="Select DNS Delegations" />
          )}
          value={dnsDelegationOptions.filter(option => dnsDelegations.includes(option.value))}
          onChange={(event, newValue) => {
            const selectedValues = newValue.map((option) => option.value);
            setDnsDelegations(selectedValues);
          }}
        />
      </FormControl>
      <FormControl className={classes.formControl} >
        <Autocomplete
          multiple
          id="waf-autocomplete"
          size="small"
          options={wafOptions} 
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          sx={{ width: 300, maxWidth: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="WAF" placeholder="Select Waf" />
          )}
          value={wafOptions.filter(option => waf.includes(option.value))}
          onChange={(event, newValue) => {
            const selectedValues = newValue.map((option) => option.value);
            setWaf(selectedValues);
          }}
        />
      </FormControl>
      <FormControl className={classes.formControl} >
        <Autocomplete
          multiple
          id="cdn-autocomplete"
          size="small"
          options={cdnOptions} 
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          sx={{ width: 300, maxWidth: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="CDN" placeholder="Select Cdn" />
          )}
          value={cdnOptions.filter(option => cdn.includes(option.value))}
          onChange={(event, newValue) => {
            const selectedValues = newValue.map((option) => option.value);
            setCdn(selectedValues);
          }}
        />
      </FormControl>
      <DataTable res={data} title="Domains Data" appLists={appLists} labels={labels}/>
    </Box>
  );
};

export default Domains;
