import React, { useState, useEffect } from 'react';
import { Box, FormControl, Checkbox, TextField, Autocomplete } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TagRenderer from 'components/TagRenderer';
import DataTable from "pages/newtable";
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 400,
    },
}));

const AppService = () => {
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const encodedData = localStorage.getItem('filtersData');
    const decodedData = atob(encodedData);
    const result = JSON.parse(decodedData);
    const email = result.email;
    const appLists = result.app_list;
    const appServiceTypes = result.app_service_type;
    const techStackOptions = result.tech_stack;
    const appServiceUrl = `${baseUrl}resource/appservice/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;

    const [data, setData] = useState([]);
    const [appList, setAppList] = useState([]);
    const [appServiceType, setAppServiceType] = useState([]);
    const [techStack, setTechStack] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${appServiceUrl}?email=${encodeURIComponent(email)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                   app_list: appList.includes('Select All') ? appLists : appList,
                   app_service_type: appServiceType.includes('Select All') ? appServiceTypes : appServiceType,
                   tech_stack: techStack.includes('Select All') ? techStackOptions : techStack,
                })
            });
            const data = await response.json();
            setData(data);
        };

        fetchData();
    }, [appList, appServiceType, techStack]);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const handleSelectAll = (newValue, list, setList, allItems) => {
        if (Array.isArray(newValue)) {
          if (newValue.includes('Select All')) {
            if (list.length === allItems.length) {
              setList([]); 
            } else {
              setList(allItems);
            }
          } else {
            setList(newValue); 
          }
        }
    };
    
    const isAllSelected = (list, allItems) => list.length === allItems.length;

    return (
        <Box m="20px">
           <FormControl className={classes.formControl} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...appLists]}
                    disableCloseOnSelect
                    value={appList}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(appList, appLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Applications" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, appList, setAppList, appLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <FormControl className={classes.formControl}>
                <Autocomplete
                    multiple
                    id="app-service-type"
                    size="small"
                    options={[{ value: 'Select All', label: 'Select All' }, ...appServiceTypes]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option.value === 'Select All' ? isAllSelected(appServiceType, appServiceTypes) : selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (<TextField {...params} label="App Service Type" />)}
                    value={appServiceType.includes('Select All') ? [{ value: 'Select All', label: 'Select All' }]: appServiceTypes.filter(option => appServiceType.includes(option.value))}
                    onChange={(event, newValue) => {
                    if (newValue.some(option => option.value === 'Select All')) {
                        if (appServiceType.length === appServiceTypes.length) {
                            setAppServiceType([]); 
                        } else {
                            setAppServiceType(appServiceTypes.map(group => group.value));
                        }
                    } else {
                        const selectedValues = newValue.map(option => option.value);
                        setAppServiceType(selectedValues);
                    }
                    }}
                    renderTags={(value, getTagProps) => (
                    <TagRenderer value={value} getTagProps={getTagProps} />
                    )}
                />
            </FormControl>
            <FormControl className={classes.formControl}>
                <Autocomplete
                    multiple
                    id="tech-stack"
                    size="small"
                    options={[{ value: 'Select All', label: 'Select All' }, ...techStackOptions]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option.value === 'Select All' ? isAllSelected(techStack, techStackOptions) : selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (<TextField {...params} label="Tech Stack" />)}
                    value={techStack.includes('Select All') ? [{ value: 'Select All', label: 'Select All' }]: techStackOptions.filter(option => techStack.includes(option.value))}
                    onChange={(event, newValue) => {
                    if (newValue.some(option => option.value === 'Select All')) {
                        if (techStack.length === techStackOptions.length) {
                            setTechStack([]); 
                        } else {
                            setTechStack(techStackOptions.map(group => group.value));
                        }
                    } else {
                        const selectedValues = newValue.map(option => option.value);
                        setTechStack(selectedValues);
                    }
                    }}
                    renderTags={(value, getTagProps) => (
                    <TagRenderer value={value} getTagProps={getTagProps} />
                    )}
                />
            </FormControl>
            <DataTable res={data} title="App Service Data"  />
        </Box>
    );
};

export default AppService;
